import { map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { Measure } from './measures.service';

export type Pattern = {
    id: number;
    trackId: number;
    measures: Measure[];
};

import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class PatternsService extends BaseService {
    public readAll(projectId: number | string, trackId: number) {
        return this.http
            .get<
                Pattern[]
            >(`${this.apiUrl}/projects/${projectId}/tracks/${trackId}/patterns`)
            .pipe(
                map((patterns) =>
                    patterns.map((pattern) => this.mapWithSubResources(pattern))
                )
            );
    }

    public update(
        projectId: number | string,
        trackId: number,
        pattern: Pattern
    ) {
        return this.http.put<Pattern>(
            `${this.apiUrl}/projects/${projectId}/tracks/${trackId}/patterns/${pattern.id}`,
            pattern
        );
    }

    public copy(
        projectId: number | string,
        trackId: number,
        destinationPatternId: number,
        sourcePatternId: number
    ) {
        return this.http.patch(
            `${this.apiUrl}/projects/${projectId}/tracks/${trackId}/patterns/${destinationPatternId}/copy`,
            { sourcePatternId }
        );
    }

    public reset(
        projectId: number | string,
        trackId: number,
        patternId: number
    ) {
        return this.http.patch(
            `${this.apiUrl}/projects/${projectId}/tracks/${trackId}/patterns/${patternId}/reset`,
            {}
        );
    }

    private mapWithSubResources(pattern: Pattern) {
        pattern.measures = [];

        return pattern;
    }
}
