import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject, OnInit
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { lastValueFrom } from 'rxjs';
import { BaseDirective } from '../../base.directive';
import { AppStateService } from '../../services/app-state.service';
import { AppStoreService } from '../../services/app-store.service';
import { AppService } from '../../services/app.service';
import { AuthService } from '../../services/auth.service';
import { ProgressService } from '../../services/progress.service';
import { S3Service } from '../../services/s3.service';

@Component({
    selector: 'st-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, FontAwesomeModule, ReactiveFormsModule]
})
export class AccountComponent extends BaseDirective implements OnInit {
    private changeDetectorRef = inject(ChangeDetectorRef);
    private fb = inject(FormBuilder);
    private title = inject(Title);
    private appService = inject(AppService);
    private appStateService = inject(AppStateService);
    private s3Service = inject(S3Service);
    private usersService = inject(AuthService);
    protected appStoreService = inject(AppStoreService);
    protected progressService = inject(ProgressService);

    get formErrors() {
        const firstNameFormItem = this.formGroup.get('firstName');
        const firstNameErrors = firstNameFormItem?.errors;
        const lastNameFormItem = this.formGroup.get('lastName');
        const lastNameErrors = lastNameFormItem?.errors;
        const emailFormItem = this.formGroup.get('email');
        const emailErrors = emailFormItem?.errors;
        const errors: string[] = [];

        if (
            firstNameErrors &&
            ((firstNameFormItem.touched && firstNameFormItem.dirty) ||
                this.isFormSubmitted)
        ) {
            firstNameErrors.required &&
                errors.push('The first name is required.');
        }

        if (
            lastNameErrors &&
            ((lastNameFormItem.touched && lastNameFormItem.dirty) ||
                this.isFormSubmitted)
        ) {
            lastNameErrors.required &&
                errors.push('The last name is required.');
        }

        if (
            emailErrors &&
            ((emailFormItem.touched && emailFormItem.dirty) ||
                this.isFormSubmitted)
        ) {
            emailErrors.required && errors.push('The email is required.');
            emailErrors.email && errors.push('The email needs to be valid.');
        }

        return errors;
    }

    get user() {
        if (!this.appStoreService.user) throw new Error('FF');

        return this.appStoreService.user;
    }

    formGroup = this.fb.group({
        firstName: [
            this.appStoreService.user?.firstName,
            [Validators.required]
        ],
        lastName: [this.appStoreService.user?.lastName, [Validators.required]],
        email: [
            this.appStoreService.user?.email,
            [Validators.required, Validators.email]
        ],
        avatar: [],
        shouldAppearInSearchResults: [
            this.appStoreService.user?.shouldAppearInSearchResults
        ]
    });
    isFormSubmitted = false;
    faPenToSquare = faPenToSquare;

    avatarFile?: File;
    avatarFileDataUrl?: string;
    serverError?: string;

    ngOnInit() {
        this.title.setTitle(`Account - ${this.appName}`);
    }

    async onNgSubmit() {
        this.isFormSubmitted = true;

        if (this.formGroup.valid) {
            const firstName = this.formGroup.get('firstName')?.value;
            const lastName = this.formGroup.get('lastName')?.value;
            const email = this.formGroup.get('email')?.value;
            const shouldAppearInSearchResults = this.formGroup.get(
                'shouldAppearInSearchResults'
            )?.value;

            if (
                !firstName ||
                !lastName ||
                !email ||
                typeof shouldAppearInSearchResults !== 'boolean'
            )
                throw new Error('FF');

            try {
                this.user.firstName = firstName;
                this.user.lastName = lastName;
                this.user.email = email;
                this.user.shouldAppearInSearchResults =
                    shouldAppearInSearchResults;

                await lastValueFrom(this.usersService.update(this.user));

                if (this.avatarFile) {
                    const avatarUploadUrl = await lastValueFrom(
                        this.usersService.generateAvatarUploadUrl()
                    );

                    const progressOpId = this.progressService.create({
                        max: 1,
                        progress: 0,
                        text: 'Uploading avatar',
                        zone: 0
                    });

                    this.s3Service
                        .upload(this.avatarFile, avatarUploadUrl)
                        .subscribe(async (v) => {
                            if (typeof v === 'number') {
                                this.progressService.update(progressOpId, v);
                            } else {
                                await this.appStateService.refreshUser();

                                this.appService.userRefreshed();
                                this.appService.alert({
                                    type: 'success',
                                    message: 'Account updated'
                                });
                            }
                        });
                } else {
                    this.appService.alert({
                        type: 'success',
                        message: 'Account updated'
                    });
                }
            } catch (e: unknown) {
                if (e instanceof HttpErrorResponse) {
                    this.serverError = e.error;

                    this.changeDetectorRef.markForCheck();
                }
            }
        }

        if (!this.formGroup.valid || this.serverError) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    onChangeAvatar(event: Event) {
        const target = event.target as HTMLInputElement;

        if (target.files?.[0]) {
            const fileReader = new FileReader();
            this.avatarFile = target.files[0];

            fileReader.onload = (e) => {
                this.avatarFileDataUrl = e.target?.result as string;

                this.changeDetectorRef.markForCheck();
            };

            fileReader.readAsDataURL(this.avatarFile);
        }
    }
}
