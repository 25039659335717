import { BaseService } from './base.service';

export type Collaboration = {
    id: number;
    userId: number;
    projectId: number;
    collaboratorId: number | null;
    externalCollaboratorEmail: string | null;
};

import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class CollaborationsService extends BaseService {
    create(
        projectId: number | string,
        newCollaboration: Partial<Collaboration>
    ) {
        return this.http.post(
            `${this.apiUrl}/projects/${projectId}/collaborations`,
            newCollaboration
        );
    }

    readAllForProject(projectId: number | string) {
        return this.http.get<Collaboration[]>(
            `${this.apiUrl}/projects/${projectId}/collaborations`
        );
    }

    delete(collaborationId: number) {
        return this.http.delete(
            `${this.apiUrl}/collaborations/${collaborationId}`
        );
    }

    resend(projectId: number | string, collaborationId: number) {
        return this.http.patch(
            `${this.apiUrl}/projects/${projectId}/collaborations/${collaborationId}/resend`,
            {}
        );
    }

    acceptInvites() {
        return this.http.patch(`${this.apiUrl}/collaborations/accept`, {});
    }
}
