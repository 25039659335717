import { map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { Effect } from './effects.service';
import { PatternTrack } from './patterns-tracks.service';
import { Pattern } from './patterns.service';
import { Plugin } from './plugins.service';
import { AudioRegion, DrumRegion, MidiRegion } from './regions.service';

type TrackBase = {
    id: number;
    projectId: number;
    userId: number;
    type: 'audio' | 'midi' | 'drum';
    colorCodeId: number | null;
    soloed: boolean;
    muted: boolean;
    name: string;
    pan: number;
    volume: number;
    position: number;
    effects: Effect[];
    regions: AudioRegion[] | MidiRegion[] | DrumRegion[];
};

type AudioMidiTrack = TrackBase & {
    inputDevice: string | null;
    armed: boolean;
};

export type AudioTrack = Omit<AudioMidiTrack, 'regions' | 'type'> & {
    type: 'audio';
    input: number | null;
    isMonitoring: boolean;
    isConsolidation: boolean;
    consolidatedTrackId: null | number;
    regions: AudioRegion[];
};

export type MidiTrack = Omit<AudioMidiTrack, 'regions' | 'type'> & {
    [key: string]: unknown;
    type: 'midi';
    channel: number | null;
    quantization: number;
    samplerSoundfont: number | null;
    externalOutput: string | null;
    externalChannel: number;
    externalMidiThru: boolean;
    synthPreset: number | null;
    isSynthActivated: boolean;
    synthLfo1Shape: number;
    synthLfo1Freq: number;
    synthLfo1Osc1: number;
    synthLfo1Osc2: number;
    synthLfo2Shape: number;
    synthLfo2Freq: number;
    synthLfo2Filter: number;
    synthLfo2Amp: number;
    synthOsc1Waveform: number;
    synthOsc1Octave: number;
    synthOsc1Detune: number;
    synthOsc2Waveform: number;
    synthOsc2Octave: number;
    synthOsc2Detune: number;
    synthSubWaveform: number;
    synthSubOctave: number;
    synthMixOsc1: number;
    synthMixOsc2: number;
    synthMixNoise: number;
    synthMixSub: number;
    synthFilterShape: number;
    synthFilterRolloff: number;
    synthFilterCutoff: number;
    synthFilterResonance: number;
    synthFilterEnvAttack: number;
    synthFilterEnvDecay: number;
    synthFilterEnvSustain: number;
    synthFilterEnvRelease: number;
    synthAmpEnvAttack: number;
    synthAmpEnvDecay: number;
    synthAmpEnvSustain: number;
    synthAmpEnvRelease: number;
    synthDrive: number;
    synthVolume: number;
    synthIsMonophonic: boolean;
    regions: MidiRegion[];
    plugins: Plugin[];
};

export type DrumTrack = Omit<TrackBase, 'regions' | 'type'> & {
    type: 'drum';
    swing: number;
    patterns: Pattern[];
    patternTracks: PatternTrack[];
    regions: DrumRegion[];
};

export type Track = AudioTrack | MidiTrack | DrumTrack;

export const isAudioTrack = (track: Track): track is AudioTrack => {
    return track.type === 'audio';
};

export const isMidiTrack = (track: Track): track is MidiTrack => {
    return track.type === 'midi';
};

export const isDrumTrack = (track: Track): track is DrumTrack => {
    return track.type === 'drum';
};

export const isAudioMidiTrack = (
    track: Track
): track is AudioTrack | MidiTrack => {
    return track.type === 'midi' || track.type === 'audio';
};

import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class TracksService extends BaseService {
    public create(projectId: number | string, newTrack: Partial<Track>) {
        return this.http
            .post<Track>(
                `${this.apiUrl}/projects/${projectId}/tracks`,
                newTrack
            )
            .pipe(map((track) => this.mapWithSubResources(track)));
    }

    public readAll(projectId: number | string) {
        return this.http
            .get<Track[]>(`${this.apiUrl}/projects/${projectId}/tracks`)
            .pipe(
                map((tracks) =>
                    tracks.map((track) => this.mapWithSubResources(track))
                )
            );
    }

    public update(projectId: number | string, track: Track) {
        const trackClone = this.cloneWithoutSubResources(track);

        return this.http.put<Track>(
            `${this.apiUrl}/projects/${projectId}/tracks/${track.id}`,
            trackClone
        );
    }

    public destroy(trackId: number, projectId: number | string) {
        return this.http.delete(
            `${this.apiUrl}/projects/${projectId}/tracks/${trackId}`
        );
    }

    public copy(
        trackId: number,
        projectId: number | string,
        bounceMappings?: { fileId: number; regionId: number }
    ) {
        return this.http.patch(
            `${this.apiUrl}/projects/${projectId}/tracks/${trackId}/copy`,
            bounceMappings
        );
    }

    public updatePosition(projectId: number | string, track: Track) {
        return this.http.patch(
            `${this.apiUrl}/projects/${projectId}/tracks/${track.id}/position`,
            { position: track.position }
        );
    }

    public cloneWithoutSubResources<T>(track: T) {
        const subResourceKeys = ['regions', 'effects'];

        return super.cloneWithoutSubResources(track, subResourceKeys);
    }

    private mapWithSubResources(track: Track) {
        track.regions = [];
        track.effects = [];

        if (track.type === 'midi') {
            track.plugins = [];
        }

        if (track.type === 'drum') {
            track.patterns = [];
            track.patternTracks = [];
        }

        return track;
    }
}
