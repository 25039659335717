import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ValidationService {
    public alphanumeric = /^[a-zÀ-ú0-9]+[a-zÀ-ú0-9#\-_.()\s]*$/i;
    public numeric = /^[0-9]+$/i;
    public nonEmpty = /^\S*.*$/i;
    public signature = /^[345]\/4$/i;
    public email =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    isValidPassword(password: string) {
        return (
            password.match(/[a-z]+/iu) &&
            password.match(/[0-9]+/iu) &&
            password.match(/^[a-z0-9!@#$%^&*()_+]{8,25}$/iu)
        );
    }
}
