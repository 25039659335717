<div class="flex justify-center items-center h-full">
    <div class="flex flex-col gap-12">
        <h1 class="text-yellow-1 text-5xl">Log in</h1>
        <p class="text-xl text-white">
            Don’t have an account?
            <a class="underline" href="register">Sign up</a>.
        </p>
        <div
            *ngIf="formErrors.length || serverError"
            class="w-96 text-red flex flex-col gap-2"
        >
            <p *ngFor="let e of formErrors">
                {{ e }}
            </p>
            <p *ngIf="serverError" class="whitespace-pre-wrap">
                {{ serverError }}
            </p>
        </div>
        <form
            class="flex flex-col gap-8"
            [formGroup]="formGroup"
            (ngSubmit)="onNgSubmit()"
        >
            <div class="flex flex-col gap-2">
                <label for="usernameOrEmail" class="text-blue-3"
                    >Username or email</label
                >
                <input
                    formControlName="usernameOrEmail"
                    id="usernameOrEmail"
                    type="text"
                    class="text-4xl bg-transparent border-b-2 border-b-blue-3 text-white pb-2"
                />
            </div>
            <div class="flex flex-col gap-2">
                <label for="password" class="text-blue-3">Password</label>
                <input
                    formControlName="password"
                    id="password"
                    type="password"
                    class="text-4xl bg-transparent border-b-2 border-b-blue-3 text-white pb-2"
                />
            </div>
            <div class="flex gap-5">
                <input
                    type="checkbox"
                    id="rememberMe"
                    formControlName="rememberMe"
                />
                <label for="rememberMe" class="text-white">Remember Me</label>
            </div>
            <button
                class="bg-yellow-1 text-black-1 py-2 text-2xl font-bold font-medium"
            >
                Log In
            </button>
        </form>
        <a href="password-reset" class="text-white underline"
            >Forgot Password</a
        >
    </div>
</div>
