import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    inject
} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ComponentInfo, MenuService } from '../../../services/menu.service';
import { MenuComponent } from '../menu.component';

@Component({
    selector: 'st-menu-container',
    templateUrl: './menu-container.component.html',
    styleUrls: ['./menu-container.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, MenuComponent]
})
export class MenuContainerComponent implements OnChanges {
    @Input() private zone!: number;

    public componentInfosOfZoneObservable!: Observable<ComponentInfo[]>;

    public elementRef = inject(ElementRef);
    private stMenuService = inject(MenuService);

    public ngOnChanges() {
        this.componentInfosOfZoneObservable =
            this.createComponentInfosOfZoneObservable();
    }

    public createComponentInfosOfZoneObservable() {
        return this.stMenuService.componentsInfoModifiedObservable.pipe(
            map((componentInfos) => {
                return componentInfos.filter((i) => {
                    return i.zone === this.zone;
                });
            })
        );
    }
}
