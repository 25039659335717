import { Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { AccountComponent } from './components/acount/account.component';
import { LoginComponent } from './components/login/login.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { PlanComponent } from './components/plan/plan.component';
import { PlansComponent } from './components/plans/plans.component';
import { SetPasswordComponent } from './components/set-password/set-password.component';
import { AppResolver } from './services/app-resolver.service';
import { AuthGuardService } from './services/auth-guard.service';
import { LoginGuardService } from './services/login-guard.service';
import { MusicteamResolver } from './services/musicteam-resolver.service';
import { ProjectResolver } from './services/project-resolver.service';

export const routes: Routes = [
    {
        path: 'register',
        component:
            environment.name === 'eveara' ? PlanComponent : PlansComponent,
        canActivate: [LoginGuardService],
        data:
            environment.name === 'eveara'
                ? { priceId: 'EVEARA-USD-Monthly' }
                : undefined
    },
    {
        path: 'register/:priceId',
        component: PlanComponent,
        canActivate: [LoginGuardService]
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [LoginGuardService]
    },
    {
        path: 'password-reset',
        component: PasswordResetComponent,
        canActivate: [LoginGuardService]
    },
    {
        path: 'set-password',
        component: SetPasswordComponent,
        canActivate: [LoginGuardService]
    },
    {
        path: 'projects',
        canActivate: [AuthGuardService],
        resolve: { data: AppResolver },
        children: [
            {
                path: '',
                loadComponent: () =>
                    import(
                        './modules/project-list/project-list.component'
                    ).then((mod) => mod.ProjectListComponent)
            },
            {
                path: ':project_id',
                resolve: {
                    data: ProjectResolver
                },
                children: [
                    {
                        path: '',
                        loadComponent: () =>
                            import('./modules/project/project.component').then(
                                (mod) => mod.ProjectComponent
                            )
                    },
                    {
                        path: 'files',
                        resolve: {
                            data: ProjectResolver
                        },
                        loadComponent: () =>
                            import(
                                './modules/project-files/project-files.component'
                            ).then((mod) => mod.ProjectFilesComponent)
                    },
                    {
                        path: 'notes',
                        loadComponent: () =>
                            import(
                                './modules/project-notes/project-notes.component'
                            ).then((mod) => mod.ProjectNotesComponent)
                    },
                    {
                        path: 'musicteam',
                        loadComponent: () =>
                            import(
                                './modules/musicteam/musicteam.component'
                            ).then((mod) => mod.MusicteamComponent),
                        resolve: {
                            data: MusicteamResolver
                        }
                    },
                    {
                        path: 'settings',
                        loadComponent: () =>
                            import(
                                './modules/project-settings/project-settings.component'
                            ).then((mod) => mod.ProjectSettingsComponent)
                    }
                ]
            }
        ]
    },
    {
        path: 'account',
        component: AccountComponent,
        canActivate: [AuthGuardService],
        resolve: { data: AppResolver }
    },
    {
        path: 'upgrade',
        component: PlansComponent,
        canActivate: [AuthGuardService],
        resolve: { data: AppResolver },
        data: { type: 'upgrade' }
    },
    {
        path: '**',
        redirectTo: 'projects',
        pathMatch: 'full'
    }
];
