import { catchError, mergeMap, tap } from 'rxjs/operators';
import { setUser } from '../helpers/error-tracking';
import { AppStateService } from './app-state.service';
import { AppStoreService } from './app-store.service';
import { BaseResolver } from './base-resolver.service';
import { CollaborationsService } from './collaborations.service';
import { StorageService } from './storage.service';

import { Injectable, inject } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class AppResolver extends BaseResolver {
    private appStateService = inject(AppStateService);
    private appStoreService = inject(AppStoreService);
    private collaborationsService = inject(CollaborationsService);
    private storageService = inject(StorageService);

    resolve() {
        if (!this.appStoreService.shareToken) {
            if (this.storageService.isNewUser) {
                return this.collaborationsService
                    .acceptInvites()
                    .pipe(mergeMap(() => this.loadAppResources()));
            } else {
                return this.loadAppResources();
            }
        } else {
            return;
        }
    }

    private loadAppResources() {
        return this.appStateService.loadResources().pipe(
            tap(() => {
                const user = this.appStoreService.user;

                if (!user) throw new Error('FF');

                setUser(user.id, user.email, user.username);
            }),
            catchError((err: Error) => this.loadingFailed(err, false))
        );
    }
}
