import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    inject
} from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
    faAngleDown,
    faAngleUp,
    faBars,
    faChevronRight,
    faCog,
    faFile,
    faListUl,
    faQuestion,
    faStickyNote
} from '@fortawesome/free-solid-svg-icons';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BaseDirective } from '../../base.directive';
import { DropdownComponent } from '../../modules/shared/components/dropdown/dropdown.component';
import { AppStoreService } from '../../services/app-store.service';
import { AppService } from '../../services/app.service';
import { AuthService } from '../../services/auth.service';
import { PaymentsService } from '../../services/payments.service';

@Component({
    selector: 'st-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, FontAwesomeModule, DropdownComponent, RouterLink]
})
export class HeaderComponent extends BaseDirective implements OnInit {
    @Input() isAppRoute!: boolean;

    environmentName = environment.name;
    wpUrl = environment.wpUrl;
    faListUl = faListUl;
    faFile = faFile;
    faBars = faBars;
    faChevronRight = faChevronRight;
    faStickyNote = faStickyNote;
    faCog = faCog;
    faQuestion = faQuestion;
    faAngleDown = faAngleDown;
    faAngleUp = faAngleUp;
    isShowingMenu = false;
    isSidebar = false;
    isSubnav = false;

    pageLinkProgressOpId?: number;
    projectId?: number;
    cbInstance?: () => void;

    public router = inject(Router);
    private changeDetectorRef = inject(ChangeDetectorRef);
    public appStoreService = inject(AppStoreService);
    private appService = inject(AppService);
    private authService = inject(AuthService);
    private paymentsService = inject(PaymentsService);

    ngOnInit() {
        this.router.events.subscribe((e) => {
            this.changeDetectorRef.markForCheck();

            if (e instanceof NavigationEnd) {
                this.projectId =
                    this.router.routerState.root.firstChild?.firstChild?.snapshot.params[
                        'project_id'
                    ];

                this.pageLinkProgressOpId !== undefined &&
                    this.progressService.update(this.pageLinkProgressOpId, 1);
            }
        });

        this.appService.listenToUserRefreshed(() => {
            this.changeDetectorRef.markForCheck();
        });
    }

    onClickPageLink(url: string) {
        const trackPagePath = '/projects/' + this.projectId;

        if (this.router.url !== trackPagePath && url === trackPagePath) {
            // The project details page can take especially long to "render". This is the only place where adding a progress bar works. It needs to be a tick before the navigation, otherwise the repainting/rerendering of the page is what takes a long time, so the UI is blocked and won't display the progress bar if not done here.
            this.pageLinkProgressOpId = this.progressService.create({
                max: 1,
                progress: 0,
                text: 'Loading',
                zone: 0
            });
        }

        setTimeout(async () => {
            await this.router.navigateByUrl(url);
        });
    }

    onClickAvatar() {
        this.isSubnav = !this.isSubnav;
        this.isShowingMenu = !this.isShowingMenu;
    }

    async logout(e: MouseEvent) {
        this.isShowingMenu = false;

        e.preventDefault();

        await lastValueFrom(this.authService.destroySession());

        this.appStoreService.logOut();
    }

    isProjectRoute(path: string) {
        return this.router.url === '/projects/' + this.projectId + path;
    }

    openBillingPortal() {
        this.paymentsService.openBillingPortal();
    }

    showSidebar() {
        this.isSidebar = !this.isSidebar;
    }
}
