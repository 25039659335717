import { Router } from '@angular/router';
import { StorageService } from './storage.service';

import { Injectable, inject } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class LoginGuardService {
    private router = inject(Router);
    private storageService = inject(StorageService);

    public canActivate() {
        return this.storageService.loggedIn()
            ? this.router.parseUrl('/')
            : true;
    }
}
