import { produce } from 'immer';

import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class ImmutableService {
    create<T>(root: T, cb: (draft: T) => void) {
        return produce(root, (draft: T) => {
            cb(draft);
        });
    }

    push<A>(array: A[], ...items: A[]) {
        return produce(array, (draft: A[]) => {
            draft.push(...items);
        });
    }

    splice<A>(array: A[], start: number, deleteCount?: number, newItem?: A) {
        return produce(array, (draft: A[]) => {
            if (deleteCount && newItem) {
                draft.splice(start, deleteCount, newItem);
            } else {
                draft.splice(start, deleteCount);
            }
        });
    }
}
