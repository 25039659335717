import { BaseService } from './base.service';

export type Effect = {
    [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    id: number;
    trackId: number;
    type: string;
    position: number;
    isActivated: boolean;
};

export type VolumeEffect = {
    volume: number;
} & Effect;

import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class EffectsService extends BaseService {
    create(
        projectId: number | string,
        trackId: number,
        effect: Partial<Effect>
    ) {
        return this.http.post<Effect>(
            `${this.apiUrl}/projects/${projectId}/tracks/${trackId}/effects`,
            effect
        );
    }

    readAll(projectId: number | string, trackId: number) {
        return this.http.get<Effect[]>(
            `${this.apiUrl}/projects/${projectId}/tracks/${trackId}/effects`
        );
    }

    update(projectId: number | string, trackId: number, effect: Effect) {
        return this.http.put(
            `${this.apiUrl}/projects/${projectId}/tracks/${trackId}/effects/${effect.id}`,
            effect
        );
    }

    delete(projectId: number | string, trackId: number, effectId: number) {
        return this.http.delete(
            `${this.apiUrl}/projects/${projectId}/tracks/${trackId}/effects/${effectId}`
        );
    }

    changePosition(
        projectId: number | string,
        trackId: number,
        effect: Effect
    ) {
        return this.http.patch(
            `${this.apiUrl}/projects/${projectId}/tracks/${trackId}/effects/${effect.id}/position`,
            { position: effect.position }
        );
    }
}
