import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import { environment } from '../../environments/environment';
import { AppStoreService } from './app-store.service';
import { StorageService } from './storage.service';

import { Injectable, inject } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class AuthGuardService {
    private router = inject(Router);
    private appStoreService = inject(AppStoreService);
    private storageService = inject(StorageService);

    public canActivate(
        activatedRouteSnapshot: ActivatedRouteSnapshot,
        routerStateSnapshot: RouterStateSnapshot
    ) {
        if (location.origin === environment.playerUrl) {
            if (!activatedRouteSnapshot.firstChild) {
                throw new Error('No activated first route');
            }

            const projectId =
                activatedRouteSnapshot.firstChild.params.project_id;

            if (projectId) {
                this.appStoreService.shareToken = projectId;

                return true;
            } else {
                window.location.href = `${environment.studioUrl}`;

                return this.router.parseUrl('/login');
            }
        } else {
            const paramsToBePersistedAndRemovedFromUrl = [
                'remember_me',
                'token',
                'is_new_user'
            ];
            const hasUnwantedParam = Object.keys(
                activatedRouteSnapshot.queryParams
            ).find((k) => paramsToBePersistedAndRemovedFromUrl.includes(k));
            const urlTree = this.router.parseUrl(routerStateSnapshot.url);
            const isLoggedIn = this.storageService.loggedIn();

            if (!isLoggedIn) {
                return this.router.parseUrl(
                    '/login?redirect=' + urlTree.toString()
                );
            } else if (hasUnwantedParam) {
                paramsToBePersistedAndRemovedFromUrl.forEach(
                    (param: string): void => {
                        delete urlTree.queryParams[param];
                    }
                );

                this.router.navigateByUrl(urlTree, { replaceUrl: true });
            }

            return isLoggedIn;
        }
    }
}
