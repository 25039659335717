import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    OnInit
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { lastValueFrom } from 'rxjs';

import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BaseDirective } from '../../base.directive';
import { AppService } from '../../services/app.service';
import { AuthService } from '../../services/auth.service';
import { ProgressService } from '../../services/progress.service';

@Component({
    selector: 'st-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, ReactiveFormsModule]
})
export class PasswordResetComponent extends BaseDirective implements OnInit {
    environmentName = environment.name;

    private changeDetectorRef = inject(ChangeDetectorRef);
    private fb = inject(FormBuilder);
    private title = inject(Title);
    private appService = inject(AppService);
    private usersService = inject(AuthService);
    protected progressService = inject(ProgressService);

    ngOnInit() {
        this.title.setTitle('Password reset - Skytracks');
    }

    get formErrors() {
        const emailFormItem = this.formGroup.get('email');
        const emailErrors = emailFormItem?.errors;
        const errors: string[] = [];

        if (
            emailErrors &&
            ((emailFormItem.touched && emailFormItem.dirty) ||
                this.isFormSubmitted)
        ) {
            emailErrors.required && errors.push('The  email is required.');
            emailErrors.email && errors.push('The  email must be valid.');
        }

        return errors;
    }

    formGroup = this.fb.group({
        email: [undefined, [Validators.required, Validators.email]]
    });
    isFormSubmitted = false;

    serverError?: string;

    async onNgSubmit() {
        this.isFormSubmitted = true;

        if (this.formGroup.valid) {
            const email = this.formGroup.get('email')?.value;

            if (!email) throw new Error('FF');

            try {
                await lastValueFrom(this.usersService.resetPassword(email));

                this.usersService.sendResetPasswordEmail(email).subscribe();

                this.appService.alert({
                    type: 'success',
                    message:
                        'If this email address is associated to a SkyTracks account, it will receive the instructions to reset the password.'
                });
            } catch (e: unknown) {
                if (e instanceof HttpErrorResponse) {
                    this.serverError = e.error;

                    this.changeDetectorRef.markForCheck();
                }
            }
        }
    }
}
