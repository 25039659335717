import { ActivatedRouteSnapshot } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { AppStoreService } from './app-store.service';
import { BaseResolver } from './base-resolver.service';
import { ProjectStateService } from './project-state.service';

import { Injectable, inject } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class ProjectResolver extends BaseResolver {
    private appStoreService = inject(AppStoreService);
    private projectStateService = inject(ProjectStateService);

    public resolve(activateRouteSnapshot: ActivatedRouteSnapshot) {
        const progressOpId = this.progressService.create({
            text: 'Loading project',
            max: 1,
            progress: 0,
            zone: 0
        });

        const routeParam = activateRouteSnapshot.paramMap.get('project_id');

        if (routeParam === null) {
            throw new Error('FF');
        }

        const projectId = this.appStoreService.shareToken
            ? routeParam
            : +routeParam;

        return this.projectStateService
            .loadProjectAndSubResources(projectId, false)
            .pipe(
                tap(() => {
                    this.appStoreService.selectedProject =
                        this.appStoreService.user?.projects?.find(
                            (p) => p.id === projectId
                        );

                    this.progressService.update(progressOpId, 1);
                }),
                catchError((err: Error) => this.loadingFailed(err, true))
            );
    }
}
