import { NgZone } from '@angular/core';
import { EVENT_MANAGER_PLUGINS, EventManager } from '@angular/platform-browser';

import { inject, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CustomEventManager extends EventManager {
    private zone = inject(NgZone);
    private plugins = inject(EVENT_MANAGER_PLUGINS);

    addEventListener(
        element: HTMLElement,
        eventName: string,
        handler: () => void
    ) {
        if (eventName.endsWith('outside-zone')) {
            eventName = eventName.split('.')[0];

            return this.zone.runOutsideAngular(() =>
                super.addEventListener(element, eventName, handler)
            );
        }

        return super.addEventListener(element, eventName, handler);
    }
}
