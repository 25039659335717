import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    OnInit
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseDirective } from '../../base.directive';
import { SwitchComponent } from '../../modules/shared/components/switch/switch.component';
import { AnalyticsService } from '../../services/analytics.service';
import { AppStoreService } from '../../services/app-store.service';
import { PaymentsService } from '../../services/payments.service';
import { ProgressService } from '../../services/progress.service';

@Component({
    selector: 'st-plans',
    templateUrl: './plans.component.html',
    styleUrls: ['./plans.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, ReactiveFormsModule, SwitchComponent]
})
export class PlansComponent extends BaseDirective implements OnInit {
    private activatedRoute = inject(ActivatedRoute);
    private changeDetectorRef = inject(ChangeDetectorRef);
    private router = inject(Router);
    private title = inject(Title);
    protected progressService = inject(ProgressService);
    private analyticsService = inject(AnalyticsService);
    private paymentsService = inject(PaymentsService);
    protected appStoreService = inject(AppStoreService);

    isMonthlyYearPlanType = true;
    type: 'upgrade' | 'register' = this.activatedRoute.snapshot.data.type;

    ngOnInit() {
        this.title.setTitle(`Register - ${this.appName}`);
    }

    onChangePricePlanType(isMonthlyYearPlanType: boolean) {
        this.isMonthlyYearPlanType = isMonthlyYearPlanType;
    }

    async onClickPlan(plan: string) {
        const priceId = `${plan}-USD-${
            this.isMonthlyYearPlanType ? 'Monthly' : 'Yearly'
        }`;

        if (this.type === 'upgrade') {
            if (priceId.startsWith('Basic')) {
                this.paymentsService.openBillingPortal(
                    this.appStoreService.user?.chargebeeSubscriptionId ||
                        undefined
                );

                return;
            } else {
                const progressOpId = this.progressService.create({
                    max: 1,
                    progress: 0,
                    text: 'Upgrading membership',
                    zone: 0
                });

                if (!this.appStoreService.user) throw new Error('FF');

                const user = await this.paymentsService.openCheckout(
                    this.appStoreService.user,
                    priceId,
                    true
                );

                this.progressService.update(progressOpId, 1);

                if (!user) return;

                this.analyticsService.sendPurchaseEvent(
                    this.paymentsService.priceIdValueMapping[priceId],
                    priceId,
                    plan
                );

                this.appStoreService.user = user;

                this.changeDetectorRef.markForCheck();
            }
        }

        await this.router.navigateByUrl(`/register/${priceId}`);
    }
}
