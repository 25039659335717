import { merge } from 'lodash';
import { type WebMidi } from 'webmidi';
import { LibAV, LibAVWrapper } from '../../assets/js/libav/libav.types';
import { SECONDS_PER_MINUTE } from '../../constants';
import { ToolTypeEnum } from '../common/enum';
import { ImmutableService } from './immutable.service';
import { Project } from './projects.service';
import { AudioRegion, DrumRegion, MidiRegion } from './regions.service';

declare let LibAV: LibAVWrapper;

type NonProjectResourceInfo = {
    collectionName: string;
    itemId: number;
};

type SelectedPatternsInfo = Record<number, SelectedPatternInfo | undefined>;

export type SelectedPatternInfo = {
    id: number;
    isSequenceActivated: boolean;
};

import { Injectable, inject } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class ProjectStoreService {
    private _project!: Project;

    hq = false;
    isRecording = false;
    selectedPatternsInfo: SelectedPatternsInfo = {};
    tool = ToolTypeEnum.MousePointer;
    drumEnginePadPatternTrackOrdering: number[] = [];
    selectedRegionId?: number;
    webMidiEnablePromise?: Promise<typeof WebMidi | void>;
    globalScrollbarInitialWidth?: number;
    libavPromise = LibAV.LibAV();
    mouseLeaveTrackControlsTimer?: number;

    get project() {
        return this._project;
    }

    set project(project: Project) {
        this._project = project;
    }

    get files() {
        const files = this.project.files;

        if (files) {
            return files;
        }
    }

    get tracks() {
        const tracks = this.project.tracks;

        if (tracks) {
            return tracks;
        }
    }

    get regions() {
        const tracks = this.project.tracks;
        const regions: (AudioRegion | MidiRegion | DrumRegion)[] = [];

        for (const t of tracks) {
            const trackRegions = t.regions;

            if (trackRegions) {
                regions.push(...trackRegions);
            }
        }

        return regions;
    }

    get decomposedTimeSig() {
        const split = this.project.signature.split('/');

        return [+split[0], +split[1]];
    }

    get nbMeasures() {
        return (
            (this.project.bpm * this.project.duration) /
            SECONDS_PER_MINUTE /
            this.decomposedTimeSig[0]
        );
    }

    get secsPerMeasure() {
        return (
            (this.decomposedTimeSig[0] * SECONDS_PER_MINUTE) / this.project.bpm
        );
    }

    private immutableService = inject(ImmutableService);

    reset() {
        this.hq = this.isRecording = false;
        this.drumEnginePadPatternTrackOrdering = [];

        delete this.selectedRegionId;
    }

    assignToResourceImmutably<T>(
        nonProjectParentResourceInfos: NonProjectResourceInfo[],
        partialResource: Record<string, unknown>
    ) {
        this.getResourceDraft(
            nonProjectParentResourceInfos,
            (resourceDraft) => {
                this.deepMergeOrSubResourcesAreReplacedWithEmptyArrays(
                    resourceDraft,
                    partialResource
                );
            }
        );

        if (
            typeof partialResource.id === 'number' &&
            nonProjectParentResourceInfos.length
        ) {
            // When setting the id of the resource, we want to return the resource, but the resource now has a new id.
            // The next line is to adjust the id of the parent resource infos so that we can get the resource
            nonProjectParentResourceInfos[
                nonProjectParentResourceInfos.length - 1
            ].itemId = partialResource.id;
        }

        return this.getResource<T>(this.project, nonProjectParentResourceInfos);
    }

    setResourceToParentResourceImmutably(
        nonProjectResourcePath: NonProjectResourceInfo[],
        resourceName: string,
        resource: unknown
    ) {
        this.getResourceDraft(nonProjectResourcePath, (resourceDraft) => {
            resourceDraft[resourceName] = resource;
        });
    }

    pushResourceToCurrentCollectionImmutably(
        nonProjectResourcePath: NonProjectResourceInfo[],
        collectionName: string,
        resource: unknown
    ) {
        this.getResourceDraft(nonProjectResourcePath, (resourceDraft) => {
            const collection = resourceDraft[collectionName];

            if (Array.isArray(collection)) {
                collection.push(resource);
            }
        });
    }

    removeResourceFromCurrentCollectionImmutably(
        nonProjectResourcePath: NonProjectResourceInfo[],
        collectionName: string,
        deletedResource: Record<string, unknown>,
        attributeNameToCheck = 'id'
    ) {
        this.getResourceDraft(nonProjectResourcePath, (resourceDraft) => {
            const collection = resourceDraft[collectionName];

            if (Array.isArray(collection)) {
                const index = collection.findIndex(
                    (r) =>
                        r[attributeNameToCheck] ===
                        deletedResource[attributeNameToCheck]
                );

                collection.splice(index, 1);
            }
        });
    }

    mergeRefresedCollectionToCurrentCollectionImmutably<T>(
        nonProjectParentResourceInfos: NonProjectResourceInfo[],
        collectionName: string,
        collection: Record<string, unknown>[]
    ) {
        this.getResourceDraft(
            nonProjectParentResourceInfos,
            (resourceDraft) => {
                this.mergeRefresedCollectionToExistingCollection(
                    resourceDraft,
                    collectionName,
                    collection
                );
            }
        );

        return this.getResource<T>(this.project, nonProjectParentResourceInfos);
    }

    getResource<T>(
        project: Project,
        nonProjectParentResourceInfos: NonProjectResourceInfo[]
    ) {
        let resource: Record<string, unknown> = project;

        nonProjectParentResourceInfos.forEach((info, i) => {
            const collection = resource[info.collectionName];

            if (!Array.isArray(collection)) throw new Error('FF');

            resource = collection?.find(
                (item: Record<string, unknown>) => item.id === info.itemId
            );

            if (!resource) {
                throw new Error('!resource');
            }

            if (i === nonProjectParentResourceInfos.length - 1) {
                return;
            }
        });

        return resource as T;
    }

    private getResourceDraft(
        nonProjectParentResourceInfos: NonProjectResourceInfo[],
        cb: (draftResource: Record<string, unknown>) => void
    ) {
        this.project = this.immutableService.create(this.project, (draft) => {
            const draftResource = this.getResource<Record<string, unknown>>(
                draft,
                nonProjectParentResourceInfos
            );

            cb(draftResource);
        });
    }

    private mergeRefresedCollectionToExistingCollection(
        collectionContext: Record<string, unknown>,
        collectionName: string,
        refreshedCollection: Record<string, unknown>[]
    ) {
        const collection = collectionContext[collectionName];

        if (!Array.isArray(collection)) throw new Error('FF');

        const addAndUpdateItems = () => {
            for (const item of refreshedCollection) {
                const existingItem = collection.find((i) => i.id === item.id);

                if (existingItem) {
                    this.deepMergeOrSubResourcesAreReplacedWithEmptyArrays(
                        existingItem,
                        item
                    );
                } else {
                    collection.push(item);
                }
            }
        };

        const removeItems = () => {
            for (let i = 0; i < collection.length; i++) {
                const item = collection[i];

                if (typeof item.id === 'number') {
                    const indexOfTrackInUpdatedList =
                        refreshedCollection.findIndex((j) => j.id === item.id);

                    if (indexOfTrackInUpdatedList < 0) {
                        const indexOfTrack = collection.findIndex(
                            (j) => j.id === item.id
                        );

                        collection.splice(indexOfTrack, 1);

                        i--;
                    }
                }
            }
        };

        addAndUpdateItems();
        removeItems();
    }

    private deepMergeOrSubResourcesAreReplacedWithEmptyArrays(
        existingItem: unknown,
        item: unknown
    ) {
        merge(existingItem, item);
    }
}
