import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { AppStoreService } from './app-store.service';
import { AuthService, User } from './auth.service';
import { ProgressService } from './progress.service';
import { Project, ProjectsService } from './projects.service';
import { StorageService } from './storage.service';

import { Injectable, inject } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class AppStateService {
    private router = inject(Router);
    private appStoreService = inject(AppStoreService);
    private usersService = inject(AuthService);
    private projectsService = inject(ProjectsService);
    private progressService = inject(ProgressService);
    private storageService = inject(StorageService);

    loadResources() {
        const userRead = (user: User) => {
            this.appStoreService.user = user;

            return this.refreshProjects().pipe(tap(updateProgress));
        };
        const updateProgress = () => {
            this.progressService.update(resourcesOpId, 1);
        };

        const resourcesOpId = this.progressService.create({
            text:
                'Loading' +
                (this.router.getCurrentNavigation()?.extractedUrl.toString() ===
                '/projects'
                    ? ' Projects List'
                    : ''),
            max: 1,
            progress: 0,
            zone: 0
        });

        return this.usersService
            .read(this.storageService.userId as number)
            .pipe(mergeMap(userRead));
    }

    async refreshUser() {
        const projects = this.appStoreService.user?.projects;

        const user = await lastValueFrom(
            this.usersService.read(this.storageService.userId as number)
        );

        user.projects = projects;
        this.appStoreService.user = user;
    }

    refreshProjects() {
        return this.projectsService.readAll().pipe(
            tap((projects) => {
                if (!this.appStoreService.user) throw new Error('FF');

                this.appStoreService.user.projects = projects;
            })
        );
    }

    createProject(project: Project) {
        const projectCreated = (p: Project) => {
            if (this.appStoreService.user?.projects) {
                this.appStoreService.user.projects.push(p);
            }
        };

        return this.projectsService.create(project).pipe(tap(projectCreated));
    }

    deleteProject(project: Project) {
        const projectDeleted = () => {
            const projects = this.appStoreService.user?.projects;

            if (!projects) {
                throw new Error('projects is not defined.');
            }

            const index = projects.findIndex((p) => {
                return p.id === project.id;
            });

            projects.splice(index, 1);
        };

        return this.projectsService
            .delete(project.id)
            .pipe(tap(projectDeleted));
    }
}
