import { Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { ProgressService } from './progress.service';

import { Injectable, inject } from '@angular/core';
@Injectable({ providedIn: 'root' })
export abstract class BaseResolver {
    protected progressService = inject(ProgressService);
    protected router = inject(Router);

    loadingFailed(err: Error, projectRoute: boolean) {
        console.log(err);

        this.progressService.deleteAll();

        if (projectRoute) {
            this.router.navigate(['/projects']);
        }

        return EMPTY;
    }
}
