import { BaseService } from './base.service';

export type Measure = {
    id: number;
    patternId: number;
    measureIndex: number;
};

import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class MeasuresService extends BaseService {
    create(
        projectId: number | string,
        trackId: number,
        patternId: number,
        measure: Partial<Measure>
    ) {
        return this.http.post<Measure>(
            `${this.apiUrl}/projects/${projectId}/tracks/${trackId}/patterns/${patternId}/measures`,
            measure
        );
    }

    readAll(projectId: number | string, trackId: number, patternId: number) {
        return this.http.get<Measure[]>(
            `${this.apiUrl}/projects/${projectId}/tracks/${trackId}/patterns/${patternId}/measures`
        );
    }
}
