import { lastValueFrom } from 'rxjs';
import { MusicteamApiService } from '../modules/musicteam/services/musicteam-api.service';
import { BaseResolver } from './base-resolver.service';
import { ProjectStoreService } from './project-store.service';

import { Injectable, inject } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class MusicteamResolver extends BaseResolver {
    private mtApiService = inject(MusicteamApiService);
    private pss = inject(ProjectStoreService);

    public async resolve() {
        const progressOpId = this.progressService.create({
            text: 'Loading music rights',
            max: 1,
            progress: 0,
            zone: 0
        });

        const promise = Promise.all([
            this.fetchProfilesAndSubResources(),
            this.fetchWorksAndSubResources()
        ]);

        this.progressService.update(progressOpId, 1);

        return promise;
    }

    async fetchProfilesAndSubResources() {
        const profilesInfo = await lastValueFrom(
            this.mtApiService.readProfiles()
        );

        const profile = profilesInfo.profiles[0];
        let dans;

        if (profile) {
            dans = (await lastValueFrom(this.mtApiService.readDans(profile.id)))
                .dans;
        }

        return { profile, dans };
    }

    async fetchWorksAndSubResources() {
        if (typeof this.pss.project.id !== 'number') throw new Error('FF');

        const worksInfo = await lastValueFrom(
            this.mtApiService.readWorks(this.pss.project.id)
        );

        const works = worksInfo.works;
        const work = works.find(
            (w) => w.skytracks_project_id === this.pss.project.id
        );
        let recordings;

        if (work) {
            recordings = (
                await lastValueFrom(this.mtApiService.readRecordings(work.id))
            ).recordings;
        }

        return { works, work, recordings };
    }
}
